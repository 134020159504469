@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@font-face {
  font-family: 'Futura Condensed';
  src: url('/assets/fonts/FuturaCondensedBold.otf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Futura Condensed';
  src: url('/assets/fonts/FuturaCondensedBoldOblique.otf');
  font-weight: bold;
  font-style: oblique;
  font-display: swap;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply leading-tight;
  @apply bg-white dark:bg-black;
  @apply text-zinc-900 dark:text-zinc-100;
}

h1 {
  @apply text-4xl font-bold tracking-tight;
}

h2 {
  @apply text-xl font-bold;
}

h3 {
  @apply text-lg font-bold;
}

h4 {
  @apply text-base font-bold;
}

a {
  color: blue;
  text-decoration: none;
}

a:visited {
  color: purple;
}

.dev {
  @apply border border-yellow-500;
}

#nprogress .bar {
  @apply bg-black dark:bg-white;
}

#nprogress .peg {
  @apply shadow-black dark:shadow-white shadow-lg;
}

.animate-spin-fast {
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.color_text {
  background: -webkit-linear-gradient(180deg, #fb44ff, #4bffe9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.color_text_1 {
  background: -webkit-linear-gradient(0deg, #69eacb, #eaccf8, #6654f1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.color_text_2 {
  background: -webkit-linear-gradient(180deg, #ff00ff, #fe511b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color_text_4 {
  background: -webkit-linear-gradient(0deg, #ff00ff, #823aa3, #100295);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.bg-800 {
  background-color: #191c1fd9;
  /* background-image: radial-gradient(#907CFF42, #FF00FF26); */
}
.outline_btn:hover {
  background-color: #ffffff00 !important;
}
.outline_btn:hover > p {
  color: #fff !important;
  -webkit-background-clip: none;
  -webkit-text-fill-color: #fff;
}

.nav_text:hover {
  background: -webkit-linear-gradient(0deg, #ff00ff, #fe511b) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
